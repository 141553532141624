@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
.bg-fixed-page {
  width: 100%;
  height: 100vh;
  background-image: url('./assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  zoom: 1.2;
}

html {
  scroll-behavior: smooth;
}

body {
  --tw-text-opacity: 1; /* Ensure full opacity */
  font-family: 'SourceSans3', sans-serif;
  color: rgba(255, 255, 255, var(--tw-text-opacity)); /* White color */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  h1 {
    @apply text-2xl font-semibold text-white; /* Example size and weight for h1 */
  }

  h2 {
    @apply text-xl font-semibold text-white; /* Example size and weight for h2 */
  }

  h3 {
    @apply text-lg font-semibold text-white; /* Example size and weight for h2 */
  }

  p {
    @apply text-base text-white; /* Base text size for paragraphs */
  }

  section {
    @apply border-2 border-white border-dotted p-4;
  }
}

@layer components {
  .blurry-background {
    @apply bg-black/30 backdrop-blur-sm; /* Semi-transparent white background with a small blur */
  }

  .blurry-background-md {
    @apply bg-black/30 backdrop-blur-md; /* Increase the blur if needed */
  }

  /* Other component styles */
}

@layer logo {
  .logo {
    width: 5.5rem;
    height: 5.5rem;
    line-height: 5.5rem;
    border: solid _size(border-width) _palette(border);
    border-radius: 100%;

    .icon {
      &:before {
        font-size: 2rem;
      }
    }
  }
}

.slide-right-to-left {
  animation: slide-right-to-left 1.5s forwards;
}

@keyframes slide-right-to-left {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.slide-left-to-right {
  opacity: 1;
  animation: slide-left-to-right 1.5s forwards;
}

@keyframes slide-left-to-right {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 5s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
